import styled from 'styled-components';

export const Price = styled.span`
  color: #e26447;
  display: block;
  white-space: nowrap;
  font-size: 30px;
  font-weight: 600;

  @media (max-width: 375px) {
    font-size: 25px;
  }
  @media (max-width: 320px) {
    font-size: 22px;
  }

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                    color: #BC2E3E;
                `;
      default:
        return ``;
    }
  }}

  & > span {
    color: #213f4e;
    font-size: 18px;
    font-weight: normal;

    ${({ pageDesign }) => {
      switch (pageDesign) {
        case 'design2':
          return `
                        color: #001C72;
                    `;
        default:
          return ``;
      }
    }}

    & > span {
      text-decoration: line-through;
    }
  }
`;
