import React, { useContext } from 'react';
import * as S from './PriceComparisonV1.styles';
import FunnelContext from '../../context/FunnelContext';
import { ProductPrice } from '../../utils/productPrice';

const PriceComparison = () => {
  const context = useContext(FunnelContext);
  const { currentCategory, currentProduct, pageDesign } = context;

  const discountedPrice = ProductPrice({
    type: `discounted_price`,
    currentCategory: currentCategory,
    currentProduct: currentProduct
  });
  const price = ProductPrice({
    type: `price`,
    currentCategory: currentCategory,
    currentProduct: currentProduct
  });

  return (
    <S.Price pageDesign={pageDesign}>
      ${discountedPrice}{' '}
      {discountedPrice === price ? (
        ``
      ) : (
        <span>
          <span>${price}</span> RRP
        </span>
      )}
    </S.Price>
  );
};

export default PriceComparison;
