import React from 'react';
import PriceComparisonV1 from './PriceComparisonV1';

const PriceComparison = props => {
  const { designVersion } = props;
  switch (designVersion) {
    default:
    case 'version1':
      return <PriceComparisonV1 {...props} />;
  }
};

export default PriceComparison;
